"use strict";
const testConditions = require("../triggerTests/testConditions");

function getNextInQueue({ steps, currentPriority = 0, data, userId, locationId, orgId, networkId }) {
   //Collate first item in each queue
   let nextQueue = {};

   for (let i = 0; i < steps.length; i++) {
      const step = steps[i];
      if (step.queuePriority) {
         if (!nextQueue.hasOwnProperty(step.queuePriority)) {
            //Test if condition exists
            const conditionsPassed = testConditions({
               conditions: step.conditions,
               data,
               userId,
               locationId,
               orgId,
               networkId,
            });

            //Store key if passed
            nextQueue[step.queuePriority] = conditionsPassed ? step.id : null;
         }
      }
   }

   for (let i = currentPriority + 1; i < 100; i++) {
      if (nextQueue[i]) {
         return nextQueue[i];
      }
   }
}

module.exports = getNextInQueue;
