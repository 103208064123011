"use strict";

document.addEventListener("DOMContentLoaded", function () {
   document.body.addEventListener("click", function (event) {
      if (event.target.classList.contains("gc-ref")) {
         let key = event.target.dataset.key;
         let language = typeof languageId !== "undefined" ? languageId : null;
         loadGlossaryTerm({ node: event.target, nodeId: key, languageId: language });
      }
   });
});

async function loadGlossaryTerm({ node, nodeId, languageId }) {
   class glossaryViewComponent extends uiComponent {
      constructor({ node, nodeId, languageId, wording, content, listenWording }) {
         super({
            node,
            insert: `tooltip`,
            class: "glossary-dialog",
            clickOutAction: "remove",
         });

         this.nodeId = nodeId;
         this.languageId = languageId;
         this.render({ wording, content, listenWording, nodeId, languageId });
         this.initializeTooltip();

         this.registerListeners({
            className: "glossary-action",
            listeners: [{ function: this.listen, events: ["click"] }],
         });
      }

      render({ wording, content, listenWording, languageId }) {
         let markup = ``;

         if (wording) {
            markup += `
            <div class="glossary-heading">
               ${this.removeGlossaryItems({ text: wording })}
               ${
                  languageId != 2112
                     ? `
               <button class="glossary-listen glossary-action pill" data-action="listen" tabindex="0">${listenWording}</button>`
                     : ``
               }
            </div>`;
         }

         markup += this.removeRecursiveReferences({ text: content });

         this.node.innerHTML = markup;
      }

      removeRecursiveReferences({ text }) {
         const nodeId = this.nodeId;
         text = text.replace(/<a class="gc-ref .?" data-key="\d*"><\/a>/g, function (match) {
            //Get key
            let key = match.match(/data-key="(\d*)"/)[1];
            if (nodeId == key) return "";
            return match;
         });

         return text;
      }

      removeGlossaryItems({ text }) {
         if (!text) return text;
         return text.replace(/<a class="gc-ref .?" data-key="\d*"><\/a>/g, "");
      }

      async listen({}) {
         if (this.audioElement) {
            return this.audioElement.play();
         }

         const response = await stepCore.server.send({
            sendData: {
               id: this.nodeId,
               languageId,
            },
            url: "cms/glossaryListen",
         });

         const mp3DataBuffer = Buffer.from(response.data, "base64");
         const mp3Blob = new Blob([mp3DataBuffer], { type: "audio/mpeg" });
         this.audioElement = new Audio(URL.createObjectURL(mp3Blob));
         this.audioElement.play();
      }
   }

   const response = await stepCore.server.send({
      sendData: {
         nodeId,
         languageId,
      },
      url: "cms/glossaryLookup",
   });

   if (response.status == "success") {
      if (!response.data) return;

      new glossaryViewComponent({
         node,
         nodeId,
         wording: response.data.wording,
         content: response.data.text,
         listenWording: response.listenWording,
      });
   }
}
