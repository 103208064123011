"use strict";

//Form CSRF
function handleFormSubmit(event) {
   // Get the form element
   const form = event.target;

   if (form.getAttribute("data-bypass-csrf")) return;

   event.preventDefault(); // Prevent the form from submitting normally

   const validationFunction = form.getAttribute("data-validation");
   if (validationFunction) {
      const isValid = formValidationFunctions[validationFunction]();
      if (!isValid) return;
   }

   // Fetch a new CSRF token
   fetch("/csrf/getToken")
      .then((response) => response.text())
      .then((token) => {
         //Remove existing csrf input field (in case second submit)
         const existingInput = form.querySelector('input[name="_csrf"]');
         if (existingInput) existingInput.remove();

         // Create a hidden input field
         const csrfInput = document.createElement("input");
         csrfInput.type = "hidden";
         csrfInput.name = "_csrf";
         csrfInput.value = token;

         // Append the hidden input field to the form
         form.appendChild(csrfInput);

         // Submit the form
         form.submit();
      });
}

//Manual add
function add({ node }) {
   node.addEventListener("submit", handleFormSubmit);
}

//Add to all on page load
document.addEventListener("DOMContentLoaded", function () {
   // Get all the forms on the page
   const forms = document.querySelectorAll("form");

   // Attach the event listener to each form
   forms.forEach((form) => {
      form.addEventListener("submit", handleFormSubmit);
   });
});

module.exports = { add };
