"use strict";
const testConditions = require("../../../models/engagement/process/triggerTests/testConditions");
const nextInQueue = require("../../../models/engagement/process/flow/getNextInQueue");

class engagementComponent {
   constructor({ panelNode, notificationNode, page, entryKey, userId, locationId, orgId, networkId }) {
      this.panelNode = $(panelNode);
      this.notificationNode = $(notificationNode);
      this.notificationsOpen = false;
      this.notificationTooltip = null;
      this.page = page;
      this.entryKey = entryKey;
      this.updatePageData({ refreshReset: true, pageLoad: true, logPageView: true });
      this.activeItems = [];
      this.userData = {};
      this.hiddenSteps = [];

      this.userId = userId;
      this.locationId = locationId;
      this.orgId = orgId;
      this.networkId = networkId;

      //Cache any checkpoint resets. This is to refreshing where multiple templates are active
      this.resetCheckpointCache = {};

      //Handle clicks
      const component = this;
      $("body").on("click", ".engagement-action", function () {
         const eventType = $(this).data("action");
         const value1 = $(this).data("value-1");
         const value2 = $(this).data("value-2");
         component.triggerEvent({ eventType, value1, value2 });
      });

      /* Tooltips */
      $("body").on("click", ".engagement-help-badge", function () {
         component.showTooltip({
            title: $(this).data("title"),
            text: $(this).data("text"),
            content: $(this).data("content"),
            target: $(this).data("target"),
            style: $(this).data("style"),
            position: $(this).data("position") ? $(this).data("position") : "top",
         });
      });

      $("body").on("click", ".engagement-tooltip-close", function () {
         component.resetModal({ key: "tooltip" });
      });
   }

   triggerEvent({ eventType, value1, value2 }) {
      if (eventType == "interactionEvent") {
         this.interactionEvent({ type: value1, key: value2 });
      }
      if (eventType == "pageEvent") {
         return this.interactionEvent({ event: value1 });
      }

      if (eventType == "notificationsToggle") this.notificationsToggle();
      if (eventType == "faqClick") this.faqToggle({ key: value1 });
      if (eventType == "moreText") this.moreTextToggle({ key: value1 });
      if (eventType == "minimize") this.hide({ key: value1 });
   }

   async updatePageData({ templateId = null, refreshReset, pageLoad, runItemUpdate, logPageView }) {
      let data = await this.getData({ templateId, runItemUpdate, logPageView });
      if (!data) {
         return console.log("Error loading engagement");
      }

      //Reset any templates with reset refresh property
      if (refreshReset) {
         data = this.resetItems({ data });
      }

      this.renderUI({ data, pageLoad });
   }

   renderUI({ data, pageLoad, checkpointResetFromCache, focusItem }) {
      if (checkpointResetFromCache) {
         data = this.resetCheckpointsFromCache({ data });
      }

      //Collate data by display type (modal, panel, notification)
      const dataByType = this.collateStepsByType({ data, focusItem });

      //Hide any existing modals
      this.resetModal({});

      //Render modals
      this.renderModals({ data: dataByType.modal, pageLoad });

      //Render panel
      this.renderPanel({ data: dataByType.panel });

      //Render notifications
      this.renderNotifications({ data: dataByType.notification });
   }

   collateStepsByType({ data }) {
      const sorted = {
         modal: [],
         panel: [],
         notification: [],
      };

      for (let i = 0; i < data.length; i++) {
         const item = data[i];

         let step = item.steps.filter((s) => s.key == item.stage)[0];
         if (!step) {
            console.log("error loading engagement step", item);
            continue;
         }

         //Skip if hidden
         if (this.hiddenSteps.includes(step.key)) continue;

         //Handle modal display
         if (step.displayType == "modal") {
            sorted.modal.push(item);
            continue;
         }

         if (item.channels.includes("panel")) {
            sorted.panel.push(item);
            continue;
         }

         if (item.channels.includes("notification")) {
            sorted.notification.push(item);
            continue;
         }
      }

      //Sort modals, so only focus appears
      sorted.modal.sort((a, b) => (a.focusItem < b.focusItem ? 1 : -1));

      return sorted;
   }

   async getData({ templateId, runItemUpdate, logPageView }) {
      const response = await stepCore.server.send({
         sendData: {
            page: this.page,
            entryKey: this.entryKey,
            templateId,
            runItemUpdate,
            logPageView,
         },
         url: "engagement/getPageData",
      });

      if (response.status == "success") {
         this.activeItems = response.data.items;
         this.userData = response.data.userData;
         return response.data.items;
      }

      if (response.status == "error") {
         console.log("error");
      }
   }

   resetItems({ data }) {
      for (let i = 0; i < data.length; i++) {
         let item = data[i];
         item = this.resetItemCheckpoint({ item });
      }
      return data;
   }

   resetItemCheckpoint({ item }) {
      // Get last reset checkpoint from history
      for (var h = item.history.length - 1; h >= 0; h--) {
         const historyItem = item.history[h];
         //Get step config
         const historyItemStep = item.steps.filter((s) => s.key == historyItem)[0];
         if (historyItemStep && historyItemStep.resetCheckpoint) {
            item.stage = historyItem;

            //Cache reset
            this.resetCheckpointCache[item.id] = {
               history: item.history.toString(),
               stage: item.stage,
            };

            return item;
         }
      }
      return item;
   }

   resetCheckpointsFromCache({ data }) {
      for (let i = 0; i < data.length; i++) {
         const item = data[i];
         const cachedCheckpoint = this.resetCheckpointCache[item.id];
         if (cachedCheckpoint) {
            if (item.stage !== cachedCheckpoint.stage && item.history.toString() == cachedCheckpoint.history) {
               item.stage = cachedCheckpoint.stage;
            }
         }
      }

      return data;
   }

   renderPanel({ data }) {
      let panelItems = data;
      if (!panelItems.length) {
         this.panelNode.addClass("hide");
         return;
      }

      let markup = ``;
      for (let i = 0; i < panelItems.length; i++) {
         const panelItem = panelItems[i];

         let step = panelItem.steps.filter((s) => s.key == panelItem.stage)[0];
         if (!step) {
            console.log("error loading engagement step", panelItem);
            continue;
         }

         let itemMarkup = this.renderStep({ step, panelItem });
         markup += itemMarkup;
      }

      this.panelNode.html(markup);
      if (markup) {
         this.panelNode.removeClass("hide");
      } else {
         this.panelNode.addClass("hide");
      }

      this.renderProgressMetre();
   }

   renderModals({ data, pageLoad }) {
      let modalItems = data;

      for (let i = 0; i < modalItems.length; i++) {
         const modalItem = modalItems[i];

         let step = modalItem.steps.filter((s) => s.key == modalItem.stage)[0];
         if (!step) {
            console.log("error loading engagement step", modalItem);
            continue;
         }

         let itemMarkup = this.renderStep({ step, panelItem: modalItem });

         //Check no delayShow conditions present
         if (!this.delayShowConditionsTriggered({ step })) {
            this.displayModal({ itemMarkup, anchor: step.elementAnchor, pageLoad, key: step.key });
            //Show max 1 modals
            return;
         }
      }
   }

   renderStep({ step, panelItem }) {
      let itemMarkup = `
         <div class="engagement-panel-item ${step.type ? step.type : ""} ${step.class ? step.class : ""}" data-key="${
         step.key
      }">`;

      if (step.type == "task") {
         if (step.hasOwnProperty("completion")) {
            itemMarkup += `
               <div class="engagement-panel-item-progress"> 
                  <svg class="progress-circle" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" data-value="${step.completion}">
                     <circle class="progress-circle" r="45" cx="50" cy="50" /> 
                     <path class="progress-circle-meter" d="M5,50a45,45 0 1,0 90,0a45,45 0 1,0 -90,0" stroke-linecap="round" stroke-linejoin="round" stroke-dashoffset="282.78302001953125" stroke-dasharray="282.78302001953125" /> 
                     <text x="50" y="50" text-anchor="middle" dominant-baseline="central" font-size="20"></text>
                  </svg>
               </div>`;
         } else {
            itemMarkup += `
               <div class="engagement-panel-item-task-icon"></div>`;
         }

         itemMarkup += `
            <div class="engagement-panel-item-task-body">`;
      }

      itemMarkup += `
               <div class="engagement-panel-title ${step.icon && step.type == "task" ? `icon-${step.icon}` : ""}">
                  ${step.title}
                  <button class="engagement-panel-minimize engagement-action" data-action="minimize" data-value-1="${
                     step.key
                  }"></button>
               </div>`;

      const faqs = this.filterItemsByCondition({ items: step.faq });

      const bodyExists = step.text || step.imageUrl || step.videoUrl || faqs.length;

      if (bodyExists) {
         itemMarkup += ` <div class="engagement-panel-body">`;

         if (step.icon && step.type !== "task") {
            itemMarkup += `
                  <div class="engagement-panel-body-icon">
                     <div class="engagement-panel-icon icon-${step.icon}"></div>
                  </div>`;
         }

         itemMarkup += `
               <div class="engagement-panel-body-main">`;

         if (step.hasOwnProperty("completion") && step.type !== "task") {
            itemMarkup += `<div class="engagement-panel-body-percentage">Setup: ${step.completion}%</div>  
                            <div class="engagement-panel-progress-container">
                                <div class="engagement-panel-progress-completed" style="width: ${step.completion}%"></div>
                            </div>`;
         }

         itemMarkup += ` 
                    ${
                       step.text == "{message}"
                          ? panelItem.message.replace(/\n/g, "<BR>")
                          : step.text
                          ? step.text.replace(/\n/g, "<BR>")
                          : ``
                    }`;

         if (step.moreText) {
            itemMarkup += `
                        <div class="engagement-read-more-link engagement-action" data-action="moreText" data-value-1="${
                           step.key
                        }">Read more</div>
                        <div class="engagement-read-more-text" data-key="${step.key}">${step.moreText.replace(
               /\n/g,
               "<BR>"
            )}
               </div>`;
         }

         if (step.videoUrl) {
            if (step.videoUrl.includes("vimeo")) {
               itemMarkup += `
            <div class="engagement-video-container"> 
               <iframe src="${step.videoUrl}" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Step v3"></iframe>
               <script src="https://player.vimeo.com/api/player.js"></script>  
            </div>
            `;
            } else {
               itemMarkup += `
            <div class="engagement-video-container">
               ${step.videoUrl}
            </div>
            `;
            }
         }

         if (step.imageUrl) {
            if (!step.imageUrl.includes("<")) {
               itemMarkup += `
                     <div class="engagement-image-container">
                        <img class="engagement-image" src="${step.imageUrl}" />
                     </div>`;
            } else {
               itemMarkup += step.imageUrl;
            }
         }
         if (faqs.length) {
            itemMarkup += `
               <div class="engagement-faq-container"> 
                  <div class="engagement-faq-label">FAQs</div> 

            `;
            for (let f = 0; f < faqs.length; f++) {
               const faq = faqs[f];

               if (faq.text) {
                  itemMarkup += `
                  <div class="engagement-faq" data-value-1="${faq.key}">
                     <div class="engagement-faq-question engagement-action" data-action="faqClick" data-value-1="${
                        faq.key
                     }">
                        ${faq.label} 
                     </div>
                     <div class="engagement-faq-text">
                        ${faq.text.replace(/\n/g, "<BR>")} 
                     </div> 
                  </div> 
                  `;
               }
            }
            itemMarkup += ` 
               </div>
            `;
         }

         itemMarkup += `
            </div>
         </div>`;
      }

      itemMarkup += this.renderInteractions({ step, bodyExists });

      if (step.type == "task") {
         itemMarkup += `
         </div>
         `;
      }

      itemMarkup += `
            </div>
         </div>`;

      return itemMarkup;
   }

   delayShowConditionsTriggered({ step }) {
      if (!step.navigation) return false;
      for (let i = 0; i < step.navigation.length; i++) {
         const navigationItem = step.navigation[i];
         if (navigationItem.action == "delayShow") {
            if (
               testConditions({
                  conditions: navigationItem.conditions,
                  data: this.userData,
                  userId: this.userId,
                  locationId: this.locationId,
                  orgId: this.orgId,
                  networkId: this.networkId,
               })
            ) {
               return true;
            }
         }
      }
   }

   async displayModal({ itemMarkup, anchor, position = "top", pageLoad = false, style, key }) {
      //Check if anchor is present
      let target;
      if (anchor) {
         anchor = anchor += ":visible";
         if (!$(anchor).length) {
            anchor = null;
         } else {
            anchor = $(anchor)[0];
            target = anchor;
         }
      }

      //Generate simple modalengagement-modal-container
      if (!anchor || !target) {
         let markup = `
      <div class="engagement-modal-container fade-in" data-key="${key}">
         <div class="engagement-modal">
            ${itemMarkup}
         </div>
      </div>
      `;

         //Short delay if rendering on page load
         setTimeout(
            () => {
               $(`body`).append(markup);
               $("html").addClass("pathway-modal-is-locked");
            },
            pageLoad ? 500 : 0
         );

         return;
      }

      this.scrollIntoViewWithOffset({ selector: target, offset: 200 });
      await this.delay(700);

      //Generate attached modal

      let markup = `
         <div class="engagement-modal-attached-container ${style ? style : ""} fade-in" data-key="${key}">
            <div class="engagement-modal-attached">
               <div class="engagement-modal-attached-arrow"></div>
               ${itemMarkup} 
            </div> 
         </div>
      `;

      const object = this;
      //Short delay if rendering on page load
      setTimeout(
         () => {
            $(`body`).append(markup);
            let tooltip = document.querySelector(`.engagement-modal-attached-container[data-key="${key}"]`);
            const arrowElement = document.querySelector(".engagement-modal-attached-arrow");

            object.notificationTooltip = stepCore.floatingUI.autoUpdate(target, tooltip, () => {
               stepCore.floatingUI
                  .computePosition(target, tooltip, {
                     placement: "top",
                     middleware: [
                        //  stepCore.floatingUI.shift(),
                        stepCore.floatingUI.offset(10),
                        stepCore.floatingUI.arrow({ element: arrowElement }),
                        stepCore.floatingUI.flip(),
                        stepCore.floatingUI.size({
                           apply({ availableWidth, availableHeight, elements }) {
                              //Bug happening with availableWidth going to zero. Put in minimum as bugfix
                              availableWidth = Math.max(availableWidth, window.innerWidth - 30);

                              Object.assign(elements.floating.style, {
                                 maxWidth: `${availableWidth}px`,
                                 maxHeight: `${availableHeight}px`,
                              });
                           },
                        }),
                     ],
                  })
                  .then(({ x, y, placement, middlewareData }) => {
                     //Bugfix to stop positioning off page
                     x = Math.max(x, 0);

                     Object.assign(tooltip.style, {
                        left: `${x}px`,
                        top: `${y}px`,
                     });

                     const { x: arrowX, y: arrowY } = middlewareData.arrow;

                     const staticSide = {
                        top: "bottom",
                        right: "left",
                        bottom: "top",
                        left: "right",
                     }[placement.split("-")[0]];

                     Object.assign(arrowElement.style, {
                        left: arrowX != null ? `${arrowX}px` : "",
                        top: arrowY != null ? `${arrowY}px` : "",
                        right: "",
                        bottom: "",
                        [staticSide]: "-4px",
                     });
                  });
            });

            $(anchor).addClass("engagement-focus");
         },
         pageLoad ? 1500 : 0
      );
   }

   resetModal({ key }) {
      if (key) {
         $(`.engagement-modal-attached-container[data-key="${key}"]`).remove();
         $(`.engagement-modal-container[data-key="${key}"]`).remove();
      } else {
         $(`.engagement-modal-attached-container`).remove();
         $(`.engagement-modal-container`).remove();
      }

      $(`.engagement-focus`).removeClass("engagement-focus");
      $("html").removeClass("pathway-modal-is-locked");
   }

   renderNotifications({ data }) {
      let notificationItems = data;

      if (!notificationItems.length) {
         this.notificationNode.addClass("hide");
         return;
      }

      let markup = `
      <button class="engagement-notification-icon-button engagement-action" data-action="notificationsToggle">
         <div class="engagement-notification-icon-badge">${notificationItems.length}</div>
      </button>

      <div class="engagement-notification-dropdown ${this.notificationsOpen ? "" : "hide"}">`;

      for (let i = 0; i < notificationItems.length; i++) {
         const item = notificationItems[i];

         let step = item.steps.filter((s) => s.key == item.stage)[0];
         if (!step) {
            console.log("error loading engagement step");
            continue;
         }

         markup += `
         <div class="engagement-notification-dropdown-item">
            <div class="engagement-notification-icon-container">
               <div class="engagement-notification-icon icon-${step.icon ? step.icon : "bell"}"></div>
            </div>
            <div class="engagement-notification-body">
               <div class="engagement-notification-dropdown-item-title">
                  ${step.title}
               </div>`;
         if (step.text) {
            markup += `  <div class="engagement-notification-dropdown-item-text">
                  ${step.text == "{message}" ? item.message : step.text}
               </div>`;
         }

         markup += this.renderInteractions({ step });

         markup += `</div>

         </div>
         `;
      }

      markup += `
      </div>
      `;

      this.notificationNode.html(markup);
      this.notificationNode.removeClass("hide");
   }

   async notificationsToggle() {
      if (!this.notificationsOpen) {
         this.notificationsOpen = true;
         this.notificationNode.find(".engagement-notification-dropdown").removeClass("hide");

         const visibleItems = this.activeItems.filter((d) => d.channels.includes("notification")).map((i) => i.id);
         //Register viewed
         await stepCore.server.send({
            sendData: {
               itemIds: visibleItems,
            },
            url: "engagement/registerView",
         });
      } else {
         this.notificationsOpen = false;
         this.notificationNode.find(".engagement-notification-dropdown").addClass("hide");
      }
   }

   renderInteractions({ step, bodyExists }) {
      let markup = ``;
      let interactions = step.interaction.filter((b) => ["button", "text", "textarea"].includes(b.type));

      //Filter interactions according to conditions
      interactions = this.filterItemsByCondition({ items: interactions });

      if (interactions.length) {
         markup += `<div class="engagement-panel-interaction ${bodyExists ? "" : "no-body"} ${
            step.interactionType == "multi" ? "multi survey" : ""
         }" data-key="${step.key}">`;
         if (step.interactionType == "multi") {
            markup += `
            <div class="question-multi-select-notice">
               You can pick multiple options
            </div>
         `;
         }
         for (let i = 0; i < interactions.length; i++) {
            const interaction = interactions[i];

            if (interaction.type == "button") {
               markup += `
                    <button class="engagement-panel-button engagement-action ${
                       interaction.class ? interaction.class : ""
                    }" data-action="interactionEvent" data-value-1="${
                  interaction.action ? interaction.action : "none"
               }" data-value-2="${interaction.key}">`;
               if (interaction.icon) {
                  markup += `<div class="engagement-button-icon icon-${interaction.icon}"></div>`;
               }
               markup += `
                        ${interaction.label}
                    </button>
                `;
            }

            if (interaction.type == "text") {
               markup += `
               ${interaction.label ? `<div class="engagement-panel-input-label">${interaction.label}</div>` : ``}
               <div class="field-container">
                  <span class="field-icon icon-${interaction.icon}"></span>
                  <input type="text" class="engagement-panel-text-input" data-key="${interaction.key}">
               </div>
               `;
            }

            if (interaction.type == "textarea") {
               markup += `
               ${interaction.label ? `<div class="engagement-panel-input-label">${interaction.label}</div>` : ``}
               <textarea class="engagement-panel-textarea" data-key="${interaction.key}"></textarea>
               `;
            }
         }
         markup += `</div>`;
      }

      return markup;
   }

   filterItemsByCondition({ items }) {
      if (!items) return [];

      const filtered = [];
      for (let i = 0; i < items.length; i++) {
         const item = items[i];
         if (
            testConditions({
               conditions: item.conditions,
               data: this.userData,
               userId: this.userId,
               locationId: this.locationId,
               orgId: this.orgId,
               networkId: this.networkId,
            })
         ) {
            filtered.push(item);
         }
      }
      return filtered;
   }

   interactionEvent({ type, key, event }) {
      //Get active interaction
      const interactionData = this.getStepInteraction({ key, event });
      if (!interactionData) {
         if (key) console.log("Interaction item not found");
         return;
      }

      //Get current Step
      const currentStep = interactionData.item.steps.filter((s) => s.key == interactionData.item.stage)[0];

      //Toggle buttons where multiselect
      if (type == "none") {
         if (currentStep.interactionType == "multi") {
            $(`.engagement-panel-button[data-value-2="${key}"]`).toggleClass("selected");
         }
      }

      //Get current selection
      const selection = this.getSelection({ currentStep });

      //Reset modals
      this.resetModal({ key: currentStep.key });

      //Get relevant navigation
      let navigation = this.getRelevantNavigation({ interaction: interactionData.interaction });

      if (navigation) {
         if (navigation.action == "goToStep") {
            return this.goToStep({ interactionData, targetStepKey: navigation.goTo, selection, navigation });
         }

         if (navigation.action == "redirect") {
            return this.redirect({ interactionData, navigation, selection });
         }

         if (navigation.action == "nextInQueue") {
            let nextInQueue = this.getNextInQueue({
               steps: interactionData.item.steps,
               currentPriority: interactionData.item.step_queue ? interactionData.item.step_queue : 0,
               data: this.userData,
               userId: this.userId,
               locationId: this.locationId,
               orgId: this.orgId,
               networkId: this.networkId,
            });
            if (nextInQueue) {
               //Where found, go to step
               return this.goToStep({ interactionData, targetStepKey: nextInQueue, selection, navigation });
            } else {
               //Where no queue items remain, complete
               return this.complete({ interactionData, selection, navigation });
            }
         }

         //Do back button
         if (navigation.action == "back") {
            return this.back({ interactionData });
         }

         //Do complete
         if (navigation.action == "complete") {
            return this.complete({ interactionData, selection, navigation });
         }

         //Do hide
         if (navigation.action == "hide") {
            this.hide({ key: currentStep.key });
            if (interactionData.interaction.runItemUpdate) {
               this.updatePageData({ refreshReset: false, runItemUpdate: true });
            }
            return;
         }

         //Delay show
         if (navigation.action == "delayShow") {
            return this.showItem({ step: currentStep });
         }
      }
   }

   getRelevantNavigation({ interaction }) {
      for (let i = 0; i < interaction.navigation.length; i++) {
         const interactionItem = interaction.navigation[i];
         if (
            testConditions({
               conditions: interactionItem.conditions,
               data: this.userData,
               userId: this.userId,
               locationId: this.locationId,
               orgId: this.orgId,
               networkId: this.networkId,
            })
         ) {
            return interactionItem;
         }
      }
   }

   getSelection({ currentStep }) {
      const selection = [];
      //Do button selections
      $(`.engagement-panel-interaction[data-key="${currentStep.key}"] .engagement-panel-button.selected`).each(
         function () {
            const key = $(this).data("value-2");
            for (let i = 0; i < currentStep.interaction.length; i++) {
               const item = currentStep.interaction[i];
               if (item.key == key) {
                  selection.push({
                     property: item.logProperty,
                     value: item.logValue,
                  });
               }
            }
         }
      );

      //Do text inputs
      $(`.engagement-panel-interaction[data-key="${currentStep.key}"] .engagement-panel-text-input,
      .engagement-panel-interaction[data-key="${currentStep.key}"] .engagement-panel-textarea`).each(function () {
         const key = $(this).data("key");
         for (let i = 0; i < currentStep.interaction.length; i++) {
            const item = currentStep.interaction[i];
            if (item.key == key) {
               selection.push({
                  property: item.logProperty,
                  value: $(this).val().trim(),
               });
            }
         }
      });

      return selection;
   }

   getNextInQueue = nextInQueue;

   async goToStep({ interactionData, targetStepKey, selection, navigation }) {
      //Get target interaction
      const targetStep = interactionData.item.steps.filter((s) => s.id == targetStepKey)[0];
      if (!targetStep) {
         return console.log("Target step not found");
      }

      //Update server
      await this.updateItem({
         itemId: interactionData.item.id,
         targetStep: targetStep.key,
         targetStepQueuePriority: targetStep.queuePriority,
         direction: "forward",
         logProperty: interactionData.interaction.logProperty,
         logValue: interactionData.interaction.logValue,
         logItems: selection,
         systemAction: interactionData.interaction.systemAction,
         runItemUpdate: interactionData.interaction.runItemUpdate,
      });

      //Redirect page
      if (navigation.redirectPage) {
         window.location.href = navigation.redirectPage;
         return;
      }

      //Add new item to history
      if (interactionData.item.history) {
         interactionData.item.history.push(targetStep.key);
      } else {
         interactionData.item.history = [targetStep.key];
      }

      //Update component data
      interactionData.item.stage = targetStep.key;

      this.renderUI({ data: this.activeItems, checkpointResetFromCache: true, focusItem: interactionData.item.id });
   }

   async redirect({ navigation, interactionData, selection }) {
      if (interactionData.interaction.logProperty || interactionData.interaction.systemAction) {
         //Update server
         await this.updateItem({
            logProperty: interactionData.interaction.logProperty,
            logValue: interactionData.interaction.logValue,
            logItems: selection,
            systemAction: interactionData.interaction.systemAction,
            runItemUpdate: interactionData.interaction.runItemUpdate,
         });
      }

      //Redirect page
      if (navigation.redirectPage) {
         window.location.href = navigation.redirectPage;
      }
   }

   back({ interactionData }) {
      //Get last action
      const previousItems = interactionData.item.history.filter((h) => h != interactionData.item.stage);
      if (previousItems.length) {
         const lastItem = previousItems[previousItems.length - 1];

         //Get target interaction
         const targetStep = interactionData.item.steps.filter((s) => s.key == lastItem)[0];
         if (!targetStep) {
            return console.log("Previous step not found");
         }

         //Update server
         this.updateItem({
            itemId: interactionData.item.id,
            targetStep: targetStep.key,
            targetStepQueuePriority: targetStep.queuePriority,
            direction: "back",
         });

         //Remove last item from history
         interactionData.item.history = previousItems;

         //Update component data
         interactionData.item.stage = targetStep.key;

         this.renderUI({ data: this.activeItems, checkpointResetFromCache: true });
      }
   }

   async complete({ interactionData, selection, navigation }) {
      //Update server
      await this.updateItem({
         itemId: interactionData.item.id,
         status: "complete",
         logProperty: interactionData.interaction.logProperty,
         logValue: interactionData.interaction.logValue,
         logItems: selection,
         systemAction: interactionData.interaction.systemAction,
      });

      //Redirect page
      if (navigation.redirectPage) {
         window.location.href = navigation.redirectPage;
      }

      //Remove item from cache
      this.activeItems = this.activeItems.filter((i) => i.id !== interactionData.item.id);

      this.renderUI({ data: this.activeItems, checkpointResetFromCache: true });
   }

   getStepInteraction({ key, event }) {
      //Get triggered step
      for (let i = 0; i < this.activeItems.length; i++) {
         const item = this.activeItems[i];
         let step = item.steps.filter((s) => s.key == item.stage)[0];
         if (!step) return;
         if (key) {
            const interaction = step.interaction.filter((o) => o.key == key)[0];
            if (interaction) {
               return {
                  item,
                  itemId: step.id,
                  interaction,
               };
            }
         }
         if (event) {
            const interaction = step.interaction.filter((o) => o.event == event)[0];
            if (interaction) {
               return {
                  item,
                  itemId: step.id,
                  interaction,
               };
            }
         }
      }
   }

   async updateItem({
      itemId,
      targetStep,
      targetStepQueuePriority,
      direction,
      status,
      logProperty,
      logValue,
      logItems = [],
      systemAction,
      runItemUpdate,
   }) {
      const response = await stepCore.server.send({
         sendData: {
            itemId,
            targetStep,
            targetStepQueuePriority,
            direction,
            status,
            page: this.page,
            logProperty,
            logValue,
            logItems,
            entryKey: this.entryKey,
            systemAction,
            runItemUpdate,
         },
         url: "engagement/updateItem",
      });

      if (response.status == "success") {
         this.activeItems = response.data.items;
         this.userData = response.data.userData;

         //Apply relevant system actions to current page
         if (systemAction) {
            this.runSystemActionsOnPage({ systemAction });
         }

         return response.data;
      }

      if (response.status == "error") {
         console.log("error");
      }
   }

   runSystemActionsOnPage({ systemAction }) {
      try {
         if (systemAction == "enableListFolders") listFoldersEnabled = true;
      } catch (error) {}
   }

   faqToggle({ key }) {
      $(`.engagement-faq[data-value-1="${key}"]`).toggleClass("open");
   }

   moreTextToggle({ key }) {
      $(`.engagement-read-more-text[data-key="${key}"]`).toggleClass("open");
   }

   scrollIntoViewWithOffset({ selector, offset }) {
      window.scrollTo({
         behavior: "smooth",
         top: selector.getBoundingClientRect().top - document.body.getBoundingClientRect().top - offset,
      });
   }

   showTooltip({ title, text, content, style, target, position }) {
      this.resetModal({ key: "tooltip" });

      let markup = `
      <div class="engagement-panel-title">
         ${title}
         <button class="engagement-panel-minimize engagement-action" data-action="minimize" data-value-1="tooltip"></button>
      </div>
      <div class="engagement-tooltip-body">${text.replace(/\n/g, "<BR>")}</div>
      <div class="engagement-tooltip-close-container">
         <button class="engagement-tooltip-close icon-ok">Close</button>
      </div>
      `;

      this.displayModal({ itemMarkup: markup, anchor: target, position, style, key: "tooltip" });
   }

   hide({ key }) {
      //Add to hidden array
      if (!this.hiddenSteps.includes(key)) {
         this.hiddenSteps.push(key);
      }
      //Add resetCheckpoint to hidden array
      const checkpointKey = this.getCheckpointKey({ key });
      if (checkpointKey && !this.hiddenSteps.includes(checkpointKey)) {
         this.hiddenSteps.push(checkpointKey);
      }

      this.resetModal({ key });
      $(`.engagement-panel-item[data-key="${key}"]`).addClass("hide");
   }

   getCheckpointKey({ key }) {
      const interactionData = this.getStepInteraction({ key });
      if (!interactionData) return;

      for (var h = interactionData.item.history.length - 1; h >= 0; h--) {
         const historyItem = interactionData.item.history[h];
         //Get step config
         const historyItemStep = item.steps.filter((s) => s.key == historyItem)[0];
         if (historyItemStep && historyItemStep.resetCheckpoint) {
            return historyItem;
         }
      }
   }

   showItem({ step }) {
      let itemMarkup = this.renderStep({ step, panelItem: null });

      //Handle modal display
      if (step.displayType == "modal") {
         this.displayModal({ itemMarkup, anchor: step.elementAnchor, key: step.key });
      }
   }

   delay(ms) {
      return new Promise(async function (resolve, reject) {
         setTimeout(() => {
            resolve();
         }, ms);
      });
   }

   renderProgressMetre() {
      // Get all the Meters
      const meters = document.querySelectorAll("svg[data-value] .progress-circle-meter");

      meters.forEach((path) => {
         // Get the length of the path
         let length = path.getTotalLength();
         // Get the value of the meter
         let value = parseInt(path.parentNode.getAttribute("data-value"));
         // Calculate the percentage of the total length
         let to = length * ((100 - value) / 100);
         // Trigger Layout in Safari hack https://jakearchibald.com/2013/animated-line-drawing-svg/
         path.getBoundingClientRect();
         // Set the Offset
         path.style.strokeDashoffset = Math.max(0, to);
         path.nextElementSibling.textContent = `${value}%`;
      });
   }
}

module.exports = engagementComponent;
