"use strict";

const toastify = require("toastify-js");

function toast({
   type, // error, access, info, success
   duration = 96000,
   heading,
   message,
   style,
}) {
   let text = `
   <div class="toastify-container">`;

   //Do heading
   const headingDefaults = {
      error: "Something went wrong",
      access: "Access denied",
      success: "Success",
   };

   if ((heading === undefined && headingDefaults[type]) || heading) {
      text += `
      <div class="toastify-heading">
         ${heading ? heading : headingDefaults[type]}
      </div>`;
   }

   //Message
   if (message) {
      text += `
      <div class="toastify-message">
         ${message}
      </div>
      `;
   }

   text += `
   </div>`;

   //Class
   let className = style;
   if (!className) className = type;

   toastify({
      text,
      duration,
      newWindow: true,
      close: true,
      className,
      gravity: "bottom",
      position: "right",
      stopOnFocus: true, // Prevents dismissing of toast on hover
      escapeMarkup: false,
   }).showToast();
}

module.exports = toast;
