"use strict";

let fontStylesheet = null;

async function loadLanguageFont({ languageId }) {
   //Reset styles if language CSS already loaded
   if (fontStylesheet) {
      fontStylesheet.parentNode.removeChild(fontStylesheet);
      fontStylesheet = null;
   }

   //Get font mapping

   const response = await stepCore.server.send({
      sendData: {
         languageId,
      },
      url: "pathways/getLanguageFont",
   });

   if (response.status != "success") return;

   const fontMapping = response.font;
   if (!fontMapping) return;

   //Get font file
   let link = document.createElement("link");
   link.rel = "stylesheet";
   link.href = `https://fonts.googleapis.com/css2?family=${fontMapping.file}&display=swap`;
   document.head.appendChild(link);

   fontStylesheet = document.createElement("style");
   fontStylesheet.textContent = fontMapping.style;
   document.head.appendChild(fontStylesheet);
}

module.exports = {
   loadLanguageFont,
   fontStylesheet,
};
