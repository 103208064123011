"use strict";

class languagePicker {
   constructor({
      elementId,
      languages,
      selectedLanguage,
      selectAction,
      displaySelected,
      closeOnDefocus = true,
      initialize = true,
   }) {
      this.elementId = elementId;
      this.selectAction = selectAction;
      this.closeOnDefocus = closeOnDefocus;

      if (initialize) {
         this.initialize({ elementId, selectAction });
      }
      this.selectedLanguage = selectedLanguage;
      this.languages = languages.sort((a, b) => (a.name > b.name ? 1 : -1));
      this.displaySelected = displaySelected;
   }

   initialize({ elementId, selectAction }) {
      if (!elementId) elementId = this.elementId;
      if (!selectAction) selectAction = this.selectAction;

      this.node = $("#" + elementId);

      //Add event listeners
      const component = this;
      this.node.on("click", ".translate-toggle", function () {
         component.open();
      });

      this.node.on("click", ".language-select-item", function () {
         const id = $(this).data("id");
         component.selectOption({ id });
      });

      this.node.on("keyup", ".language-search", function (e) {
         //Enter key
         if (e.keyCode == 13) {
            e.preventDefault();
            return component.enterKeyPress();
         }

         //Down key
         if (e.keyCode == 40) {
            e.preventDefault();
            return component.downKeyPress();
         }

         //Up Key
         if (e.keyCode == 38) {
            e.preventDefault();
            return component.upKeyPress();
         }

         //Other keystroke
         component.keypress();
      });

      //Look for defocus
      if (this.closeOnDefocus) {
         $("body").click(function (e) {
            if (e.target.id == elementId || isChildOfNode(e)) {
               //Click inside element
            } else {
               if (component.displaySelected && component.selectedLanguage) {
                  $(".translate-lookup-options").html("");
               } else {
                  component.close();
               }
            }

            function isChildOfNode(e) {
               if (!$(e.target).closest("body").length) return true; //Bugfix: handle elements immediately removed on click
               const object = $(e.target).closest(".translate-panel");

               if (!object.length) return false;
               const objectId = object.prop("id");
               if (objectId == elementId) return true;
            }
         });
      }

      this.selectAction = selectAction;
   }

   open() {
      this.node.addClass("open");
      $(".definition-selector-search").val("");
      this.updateList({ string: null });
      this.node.find(".language-search").focus();
   }

   close() {
      this.node.removeClass("open");
   }

   updateList({ string }) {
      let filteredList = string && string.length ? this.filterList({ string }) : this.languages;

      let markup = ``;
      for (let i = 0; i < filteredList.length; i++) {
         const item = filteredList[i];
         markup += `
         <div class="language-select-item" data-id="${item.id}">
            <div class="language-select-item-en">${item.name}</div>
            ${item.versions.length ? `<div class="language-select-item-native">${item.versions[0].wording}</div>` : ``} 
         </div>`;
      }

      this.node.find(`.translate-lookup-options`).html(markup);
   }

   enterKeyPress() {
      const selected = $(".language-select-item.selected");
      if (selected.length == 1) {
         const id = selected.data("id");
         this.selectOption({ id });
      }
   }

   downKeyPress() {
      const selected = this.node.find(".language-select-item.selected");
      if (!selected.length) {
         return this.node.find(".language-select-item").first().addClass("selected");
      }

      if (selected.next().hasClass("language-select-item")) {
         selected.removeClass("selected");
         selected.next().addClass("selected");
      }
   }

   upKeyPress() {
      const selected = this.node.find(".language-select-item.selected");
      if (selected.prev().hasClass("language-select-item")) {
         selected.removeClass("selected");
         selected.prev().addClass("selected");
      }
   }

   keypress() {
      let string = this.node.find(".language-search").val().trim();
      this.updateList({ string });
   }

   filterList({ string }) {
      let filtered = [];
      for (let i = 0; i < this.languages.length; i++) {
         const language = this.languages[i];
         let matchScore = this.matchString({ referenceString: language.name, testString: string });
         for (let v = 0; v < language.versions.length; v++) {
            const version = language.versions[v];
            const versionMatchScore = this.matchString({ referenceString: version.wording, testString: string });
            matchScore = Math.max(matchScore, versionMatchScore);
         }
         language.score = matchScore;
         if (matchScore) filtered.push(language);
      }

      filtered.sort((a, b) => (a.score < b.score ? 1 : -1));

      return filtered;
   }

   matchString({ referenceString, testString }) {
      referenceString = referenceString.toLowerCase();
      testString = testString.toLowerCase();
      if (referenceString.match(new RegExp("^" + testString + ".*", "gi"))) {
         return 2;
      }

      if (referenceString.includes(testString)) return 1;

      return 0;
   }

   selectOption({ id }) {
      this.selectedLanguage = id;
      this.selectAction(arguments[0]);
      for (let i = 0; i < this.languages.length; i++) {
         const language = this.languages[i];
         if (language.id == id) {
            $(".language-search").val(language.name);
         }
      }
      $(".translate-lookup-options").html("");
   }
}

module.exports = languagePicker;
