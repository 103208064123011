"use strict";

let floatingMenuInitialized = false;

class floatingMenu {
   constructor({}) {
      const component = this;

      this.open = false;

      //Register clicks
      if (!floatingMenuInitialized) {
         $("body").on("click", ".floating-menu-toggle", function () {
            component.toggleFloatingMenu({ node: $(this) });
         });

         $("body").click(function (e) {
            if (component.open) {
               const componentNode = document.querySelector(".floating-menu.show");

               if (
                  e.target.classList.contains("floating-menu") ||
                  (componentNode && componentNode.contains(e.target))
               ) {
                  //Click inside
               } else {
                  //Click out
                  component.closeAllFloatingMenus();
               }
            }
         });
      }

      floatingMenuInitialized = true;
   }

   toggleFloatingMenu({ node }) {
      const parent = node;
      const child = $(parent.data("floating-menu"));
      const isOpen = child.hasClass("show");
      const focus = node.data("focus");

      const component = this;

      //Close all other items
      this.closeAllFloatingMenus();

      if (!isOpen) {
         child.addClass("show").removeClass("editor");
         $(`.floating-menu-edit`).removeClass("active");

         let tooltip = child[0];
         const arrowElement = document.querySelector(".floating-menu-arrow");
         const target = parent[0];

         stepCore.floatingUI.autoUpdate(target, tooltip, () => {
            stepCore.floatingUI
               .computePosition(target, tooltip, {
                  placement: "bottom",
                  middleware: [
                     //  stepCore.floatingUI.shift(),
                     stepCore.floatingUI.offset(10),
                     stepCore.floatingUI.arrow({ element: arrowElement }),
                     stepCore.floatingUI.flip(),
                     stepCore.floatingUI.size({
                        apply({ availableWidth, availableHeight, elements }) {
                           //Bug happening with availableWidth going to zero. Put in minimum as bugfix
                           availableWidth = Math.max(availableWidth, window.innerWidth - 30);

                           Object.assign(elements.floating.style, {
                              maxWidth: `${availableWidth}px`,
                              maxHeight: `${availableHeight}px`,
                           });
                        },
                     }),
                  ],
               })
               .then(({ x, y, placement, middlewareData }) => {
                  //Bugfix to stop positioning off page
                  x = Math.max(x, 0);

                  Object.assign(tooltip.style, {
                     left: `${x}px`,
                     top: `${y}px`,
                  });

                  const { x: arrowX, y: arrowY } = middlewareData.arrow;

                  const staticSide = {
                     top: "bottom",
                     right: "left",
                     bottom: "top",
                     left: "right",
                  }[placement.split("-")[0]];

                  if (arrowElement) {
                     Object.assign(arrowElement.style, {
                        left: arrowX != null ? `${arrowX}px` : "",
                        top: arrowY != null ? `${arrowY}px` : "",
                        right: "",
                        bottom: "",
                        [staticSide]: "-4px",
                     });
                  }
               });

            setTimeout(() => {
               component.open = true;
               if (focus) {
                  $(focus).focus();
               }
            }, 1);
         });
      }
   }

   closeAllFloatingMenus() {
      const component = this;
      $(".floating-menu-toggle").each(function () {
         component.closeFloatingMenu({ node: $(this) });
      });

      this.open = false;
   }

   closeFloatingMenu({ node }) {
      const child = $(node.data("floating-menu"));
      child.removeClass("show");
   }
}

module.exports = floatingMenu;
