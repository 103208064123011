"use strict";
const dayjs = require("dayjs");

function testConditions({ conditions, data, anyTrue, userId, locationId, orgId, networkId, templateId }) {
   //Do negative sets
   if (anyTrue) {
      for (let i = 0; i < conditions.length; i++) {
         const condition = conditions[i];
         if (conditionIsTrue({ condition, data, templateId })) return true;
      }
      return false;
   }

   if (!conditions.length) return true;

   //Pass if all conditions in any set pass
   for (let i = 0; i < conditions.length; i++) {
      const set = conditions[i].condition;
      if (allSetItemsTrue({ set, data, templateId })) {
         return true;
      }
   }

   //Test if all items true
   function allSetItemsTrue({ set, data, templateId }) {
      for (let i = 0; i < set.length; i++) {
         const condition = set[i];
         if (!conditionIsTrue({ condition, data, templateId })) {
            return false;
         }
      }
      return true;
   }

   //Test if condition is true
   function conditionIsTrue({ condition, data, templateId }) {
      //Get value
      let value = getValue({ condition, data, userId, locationId, orgId, networkId, templateId });

      //Test condition
      if (condition.operator == "exists") {
         return ![undefined, null].includes(value);
      }
      if (condition.operator == "notExists") {
         return [undefined, null].includes(value);
      }
      if (condition.operator == "olderThan") {
         if (!value) return false;
         if (condition.date) {
            return value.diff(dayjs(condition.date)) < 0;
         } else {
            let triggerValueDate = dayjs().subtract(condition.value, "day");
            return value.diff(triggerValueDate) < 0;
         }
      }
      if (condition.operator == "olderThanOrNotExists") {
         if (!value) return true;
         if (condition.date) {
            return value.diff(dayjs(condition.date)) < 0;
         } else {
            let triggerValueDate = dayjs().subtract(condition.value, "day");
            return value.diff(triggerValueDate) < 0;
         }
      }
      if (condition.operator == "newerThan") {
         if (!value) return false;
         if (condition.date) {
            return value.diff(dayjs(condition.date)) > 0;
         } else {
            let triggerValueDate = dayjs().subtract(condition.value, "day");
            return value.diff(triggerValueDate) > 0;
         }
      }

      if (condition.operator == "<") {
         return parseInt(value) < parseInt(condition.value);
      }
      if (condition.operator == ">") {
         return parseInt(value) > parseInt(condition.value);
      }
      if (condition.operator == "=") {
         //Where value in format {1,4} do in array test
         if (condition.value && condition.value.match(/\{.*\}/)) {
            return isInSet({ value, setString: condition.value });
         } else {
            return isMatch({ value1: value, value2: condition.value });
         }
      }
      if (condition.operator == "!=") {
         if (condition.value && condition.value.match(/\{.*\}/)) {
            return !isInSet({ value, setString: condition.value });
         } else {
            return !isMatch({ value1: value, value2: condition.value });
         }
      }
   }

   function isMatch({ value1, value2 }) {
      //Handling for booleans / type mismatches
      value1 = cleanValue({ value: value1 });
      value2 = cleanValue({ value: value2 });
      return value1 == value2;
   }

   function cleanValue({ value }) {
      if ([null, undefined, 0, false, "0", "false"].includes(value)) return 0;
      if ([1, true, "1", "true"].includes(value)) return 1;
      return value;
   }

   function isInSet({ value, setString }) {
      const values = setString.replace("{", "").replace("}", "").split(",");
      value = value ? value.toString() : "";
      return values.includes(value);
   }

   //Get value
   function getValue({ condition, data, userId, locationId, orgId, networkId, templateId }) {
      //Users
      if (condition.source == "user") {
         let user = data.user.filter((u) => u.id == userId)[0];
         if (!user) return null;
         if (condition.property == "role") {
            return user.role;
         }
         if (condition.property == "last_login") {
            return user.last_login ? dayjs(user.last_login) : null;
         }

         if (condition.property == "engagement_status") {
            return user.engagement_status;
         }

         if (condition.property == "networkId") return user.network_id;
         if (condition.property == "orgId") return user.org_id;
      }

      //Engagement Items
      if (condition.source == "engagementItems") {
         if (condition.property == "created_count") {
            return data.engagementItems.filter((i) => i.template_id == templateId).length;
         }
      }

      //Engagement logs
      if (condition.source == "engagementLogs") {
         return getEngagementLogValue({ property: condition.property, logs: data.engagementLogs });
      }

      //Org unit items
      if (condition.source == "orgUnit") {
         let item = data.orgUnit.filter((i) => i.property == condition.property)[0];
         return item.value;
      }

      //Config items
      if (condition.source == "config") {
         let item = data.config.filter((i) => i.property == condition.property && i.user_id == userId)[0];
         if (item) return item.value_1;
      }

      //Page selector
      if (condition.pageSelector && typeof window == "object") {
         let visibleCount = $(condition.pageSelector + ":visible").length;
         return visibleCount ? visibleCount : null;
      }
   }
}

function getEngagementLogValue({ property, logs }) {
   for (var i = logs.length - 1; i >= 0; i--) {
      const logItem = logs[i];
      if (logItem.property == property) {
         return logItem.value;
      }
   }

   return null;
}

module.exports = testConditions;
