import { computePosition, flip, size, arrow, shift, offset, autoUpdate } from "@floating-ui/dom";

const uiComponent = require("../public/js/components/uiComponent.js");
const wordingModule = require("../public/js/components/wordingModule.js");

global.module = {};

global.stepCore = {
   server: require("../public/js/server.js"),
   floatingUI: {
      computePosition,
      flip,
      offset,
      size,
      arrow,
      shift,
      autoUpdate,
   },
   engagementComponent: require("../public/js/engagement/display.js"),
   floatingMenu: require("../public/js/components/floatingMenu.js"),
   languagePicker: require("../public/js/components/language-picker.js"),
   formCsrf: require("../public/js/formCsrf.js"),
   translate: require("../public/js/glossary.js"),
   toast: require("../public/js/components/toast.js"),
   uiComponent: uiComponent.uiComponent,
   components: {
      dialogComponent: uiComponent.dialogComponent,
   },
   wording: wordingModule,
   languageFont: require("../public/js/pathways/components/translation-font.js"),
   findInTree: require("../models/definitions/findInTree"),
   formatPostcode: require("../modules/search/locations/formatPostcode"),
};
