"use strict";

function format({ postcode, escapeSpace }) {
   postcode = postcode.toUpperCase().trim().replace(/\s/g, "");
   if (!postcode.includes(" ")) {
      postcode = postcode.substring(0, postcode.length - 3) + " " + postcode.slice(-3);
   }

   if (escapeSpace) {
      postcode = postcode.replace(" ", "%20");
   }

   return postcode;
}

module.exports = format;
